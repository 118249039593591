import { SettingKey } from '@/models/setting'

export const getSettingPostfixByKey = (key: SettingKey) => {
  switch (key) {
    case SettingKey.ADMIN_FEE:
    case SettingKey.PROMOTIONAL_ADMIN_FEE:
      return '[%]'
    case SettingKey.default_extralarge_policy_price:
    case SettingKey.default_large_policy_price:
    case SettingKey.default_medium_policy_price:
    case SettingKey.default_small_policy_price:
    case SettingKey.default_small_policy_fee:
    case SettingKey.default_medium_policy_fee:
    case SettingKey.default_large_policy_fee:
    case SettingKey.default_extralarge_policy_fee:
      return '[₼ / day]'
    case SettingKey.maximal_deductible_amount:
    case SettingKey.minimal_deductible_amount:
      return '[₼ / trip]'
    case SettingKey.INSURANCE_FEE:
      return '[¢]'

    case SettingKey.CAR_SEARCH_RADIUS:
      return '[mt]'

    default:
      return ''
  }
}
