
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'CommonError',
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: 'Unknown error was occured',
    },
    onRetryPress: {
      required: true,
      type: Function as PropType<() => void>,
    },
  },
})
