import { defineStore } from 'pinia'
import { ElNotification } from 'element-plus'

import settingsApi from '@/api/settings'

import { SettingKey } from '@/models/setting'

import { parseErrors } from '@/helpers/error-parsers'
import { commonCase } from '@/helpers/strings'

import getInitialState, { SettingsByGroup, State } from './state'

export const useSettingsStore = defineStore({
  id: 'settings-store',
  state: getInitialState,
  actions: {
    async getBrandListModelCSV(key: SettingKey) {
      try {
        this.$patch((state: State) => {
          const updt = state.settingsModule.updating

          updt.error = null
          if (!updt.loadingKeys.includes(key)) updt.loadingKeys.push(key)
        })
        const response = await settingsApi.getBrandListModelCSV()

        const url = URL.createObjectURL(
          new Blob([(response.data as unknown) as Blob])
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'brand-models-csv.csv')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        ElNotification({
          type: 'success',
          duration: 6000,
          message: 'Brand models list downloaded',
        })
      } catch (e) {
        const { error } = parseErrors(e)
        this.settingsModule.error = error

        ElNotification({ message: error, duration: 10000, type: 'error' })
      } finally {
        this.$patch((state: State) => {
          const updt = state.settingsModule.updating

          const loadingIndex = updt.loadingKeys.indexOf(key)
          if (loadingIndex != -1) updt.loadingKeys.splice(loadingIndex, 1)
        })
      }
    },
    async getBrandListModelXLSX(key: SettingKey) {
      try {
        this.$patch((state: State) => {
          const updt = state.settingsModule.updating

          updt.error = null
          if (!updt.loadingKeys.includes(key)) updt.loadingKeys.push(key)
        })
        const response = await settingsApi.getBrandListModelXLSX()

        const url = URL.createObjectURL(
          new Blob([(response.data as unknown) as Blob])
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'brand-models-xlsx.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        ElNotification({
          type: 'success',
          duration: 6000,
          message: 'Brand models list downloaded',
        })
      } catch (e) {
        const { error } = parseErrors(e)
        this.settingsModule.error = error

        ElNotification({ message: error, duration: 10000, type: 'error' })
      } finally {
        this.$patch((state: State) => {
          const updt = state.settingsModule.updating

          const loadingIndex = updt.loadingKeys.indexOf(key)
          if (loadingIndex != -1) updt.loadingKeys.splice(loadingIndex, 1)
        })
      }
    },
    async getAppSettings() {
      try {
        this.$patch({
          settingsModule: { isLoading: true, error: null },
        })

        const settings = (await settingsApi.getAppSettings()).data.data

        this.settingsModule.list = settings
      } catch (e) {
        const { error } = parseErrors(e)
        this.settingsModule.error = error

        ElNotification({ message: error, duration: 10000, type: 'error' })
      } finally {
        this.$patch({
          settingsModule: { isLoading: false, isInitialLoadingDone: true },
        })
      }
    },

    async updateAppSetting(id: number, key: SettingKey, value: string) {
      try {
        this.$patch((state: State) => {
          const updt = state.settingsModule.updating

          updt.error = null
          if (!updt.loadingKeys.includes(key)) updt.loadingKeys.push(key)

          const successIndex = updt.successKeys.indexOf(key)
          if (successIndex !== -1) updt.successKeys.splice(successIndex, 1)
        })

        const setting = (await settingsApi.updateAppSetting(id, value)).data
          .data

        this.$patch((state: State) => {
          const updt = state.settingsModule.updating

          if (!updt.successKeys.includes(key)) updt.successKeys.push(key)

          const settingInList = state.settingsModule.list.find(
            item => item.id === id
          )

          if (settingInList) settingInList.value = setting.value
        })

        const message = `${commonCase(key, true)} value successfully updated.`

        ElNotification({ type: 'success', duration: 6000, message })
      } catch (e) {
        const { error, validationErrorsRaw } = parseErrors(e)

        this.settingsModule.updating.error = error

        const message = `An error while updating ${key}: "${validationErrorsRaw?.[0]
          .message || error}"`

        ElNotification({ message, type: 'error', duration: 10000 })
      } finally {
        this.$patch((state: State) => {
          const updt = state.settingsModule.updating

          const loadingIndex = updt.loadingKeys.indexOf(key)
          if (loadingIndex != -1) updt.loadingKeys.splice(loadingIndex, 1)
        })
      }
    },
  },
  getters: {
    settingsByGroup(state): SettingsByGroup {
      return state.settingsModule.list.reduce<SettingsByGroup>((acc, value) => {
        if (acc[value.group]) {
          acc[value.group]?.push(value)
        } else {
          acc[value.group] = [value]
        }

        return acc
      }, {})
    },
  },
})
