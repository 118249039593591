export enum SettingGroup {
  SEARCH = 'search',
  PAYMENTS = 'payments',
  INSURANCE_FEE = 'insurance_fee',
  COVERAGE_PACKAGE = 'coverage_package',
}

export enum SettingKey {
  CAR_SEARCH_RADIUS = 'car_search_radius',
  PROMOTIONAL_ADMIN_FEE = 'promotional_admin_fee',
  ADMIN_FEE = 'admin_fee',
  INSURANCE_FEE = 'insurance_fee',
  INSURANCE_MODEL_LIST_CSV = 'insurance_model_list_CSV',
  INSURANCE_MODEL_LIST_XLSX = 'insurance_model_list_XLSX',
  default_extralarge_policy_price = 'default_extralarge_policy_price',
  default_large_policy_price = 'default_large_policy_price',
  default_medium_policy_price = 'default_medium_policy_price',
  default_small_policy_price = 'default_small_policy_price',
  maximal_deductible_amount = 'maximal_deductible_amount',
  minimal_deductible_amount = 'minimal_deductible_amount',
  default_small_policy_fee = 'default_small_policy_fee',
  default_medium_policy_fee = 'default_medium_policy_fee',
  default_large_policy_fee = 'default_large_policy_fee',
  default_extralarge_policy_fee = 'default_extralarge_policy_fee',
}

export interface Setting {
  id: number
  key: SettingKey
  group: SettingGroup
  value: string
  createdAt: string
  updatedAt: string
}

export type SettingUpdateableParams = Partial<Pick<Setting, 'value'>>
